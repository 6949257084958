
.for-login{
    display: flex;
    height: 100vh;
    align-items: center !important;
    justify-content: center !important;
  }
  label{
    color: black;
  }
  
